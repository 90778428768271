import React from "react";
import SEO from "components/seo";
import {
  ContactForm,
  FullPhoto,
  Hero,
  Layout,
  Navigation,
  TextBox,
  VideoBox,
  WhatWeDid,
  VideoGallery,
} from "components/CaseLayout";
import { PIZZA } from "shared/Types/cases";
import { useCaseData } from "shared/Hooks/CasesNew/useCaseData";

const PizzaBox = () => {
  const data = useCaseData(PIZZA);
  return (
    <Layout>
      <SEO title={data.title} description={data.seo} />
      <Hero data={data.hero} />
      <Navigation type={PIZZA} />
      <WhatWeDid data={data.what_we_did} />
      <FullPhoto data={data.full_images.first} />
      <VideoBox data={data.video_box.first} />
      <FullPhoto data={data.full_images.second} />
      <VideoBox data={data.video_box.second} reverse={1} />
      <VideoBox data={data.video_box.third} />
      <FullPhoto data={data.full_images.third} />
      <VideoGallery data={data.video_gallery.first} />
      <TextBox data={data.text_boxes.first} />
      <FullPhoto data={data.full_images.fourth} />
      <ContactForm />
    </Layout>
  );
};

export default PizzaBox;
